@import './../../../../scss/theme-bootstrap';

///
///
/// \brief Styles for multi-product grids
///
///

.product-grid-wrapper {
  max-width: $max-width;
  margin: 0 auto;
  position: relative;
}

.product-grid {
  @include pie-clearfix;
  padding: 0 5px;
  @include breakpoint($medium-up) {
    padding: 0;
  }
  &__content {
    display: flex;
    flex-wrap: wrap;
    .slick-track {
      display: flex;
    }
  }
  &--carousel {
    padding: 0;
    .slick-prev,
    .slick-next {
      top: 30%;
      @include breakpoint($large-up) {
        top: 33%;
      }
    }
  }
}

.product-grid__content {
  @include pie-clearfix;
}

// Prevent FOUC
.product-carousel {
  visibility: hidden;
  &.slick-initialized {
    visibility: visible;
  }
}

// Default grid is:
// small = 2 columns
// medium = 3 columns
// large = 4 columns
.product-grid__item {
  position: relative;
  float: $ldirection;
  width: 100%;
  margin: 0 0 20px 0;
  padding: 0 0 45px 0; // padding-bottom should match quickshop button height + gutter height. Needed to align buttons evenly across.
  @include breakpoint($medium-up) {
    width: 33.333%;
    padding: 14px 14px 40px;
    margin-bottom: 30px;
  }
  .product-grid--carousel & {
    margin-bottom: 0;
  }
  &--tout {
    // touts are always 100% on mobile regardless of colspan value
    width: 100%;
    @include breakpoint($medium-up) {
      width: 33.333%;
    }
    @include breakpoint($large-up) {
      width: 25%;
    }
    .product-grid--large-cols-4 & {
      @include breakpoint($large-up) {
        width: 25%;
      }
    }
    .product-grid--large-cols-3 & {
      @include breakpoint($large-up) {
        width: 33.333%;
        padding-bottom: 0;
      }
    }
    .product-grid--large-cols-2 & {
      @include breakpoint($large-up) {
        width: 50%;
      }
    }
    .product-grid--large-cols-1 & {
      @include breakpoint($large-up) {
        width: 100%;
      }
    }
    .product-grid--medium-cols-4 & {
      @include breakpoint($medium-only) {
        width: 25%;
      }
    }
    .product-grid--medium-cols-3 & {
      @include breakpoint($medium-only) {
        width: 33.333%;
      }
    }
    .product-grid--medium-cols-2 & {
      @include breakpoint($medium-only) {
        width: 50%;
      }
    }
    .product-grid--medium-cols-1 & {
      @include breakpoint($medium-only) {
        width: 100%;
      }
    }
    .product-grid--small-cols-1 & {
      @include breakpoint($medium-down) {
        width: 100%;
      }
    }
    .product-grid--small-cols-2 & {
      @include breakpoint($medium-down) {
        width: 50%;
      }
    }
    .product-grid--small-cols-3 & {
      @include breakpoint($medium-down) {
        width: 33.33%;
      }
    }
    .product-grid--small-cols-4 & {
      @include breakpoint($medium-down) {
        width: 25%;
      }
    }
  }
  &--colspan-2.product-grid__item {
    .product-grid__content & {
      @include breakpoint($medium-up) {
        width: 66.66667%;
      }
      @include breakpoint($large-up) {
        width: 66.66667%;
        max-width: 66.66667%;
      }
    }
  }
  &--colspan-3.product-grid__item {
    .product-grid__content & {
      @include breakpoint($medium-up) {
        width: 100%;
      }
      @include breakpoint($large-up) {
        width: 75%;
      }
    }
  }
  &--colspan-4.product-grid__item {
    .product-grid__content & {
      @include breakpoint($medium-up) {
        width: 100%;
      }
    }
  }
  &--slide {
    @include breakpoint($medium-down) {
      .product-grid--carousel--small-1 & {
        height: auto !important; // disable equalHeights for product grid carousel on mobile only when one slide
        opacity: 0.5;
        padding: 0;
        transition: opacity 0.4s ease;
        &.slick-active {
          opacity: 1;
        }
      }
    }
  }
  .product-grid--large-cols-4 & {
    @include breakpoint($large-up) {
      width: 25%;
      max-width: 25%;
    }
  }
  .product-grid--large-cols-3 & {
    @include breakpoint($large-up) {
      width: 33.333%;
      max-width: 33.333%;
    }
  }
  .product-grid--large-cols-2 & {
    @include breakpoint($large-up) {
      width: 50%;
      max-width: 50%;
    }
  }
  .product-grid--large-cols-1 & {
    @include breakpoint($large-up) {
      width: 100%;
    }
  }
  .product-grid--medium-cols-4 & {
    @include breakpoint($medium-only) {
      width: 25%;
      max-width: 25%;
    }
  }
  .product-grid--medium-cols-3 & {
    @include breakpoint($medium-only) {
      width: 33.333%;
      max-width: 33.333%;
    }
  }
  .product-grid--medium-cols-2 & {
    @include breakpoint($medium-only) {
      width: 50%;
      max-width: 50%;
    }
  }
  .product-grid--medium-cols-1 & {
    @include breakpoint($medium-only) {
      width: 100%;
    }
  }
  .product-grid--small-cols-1 & {
    @include breakpoint($medium-down) {
      width: 100%;
    }
  }
  .product-grid--small-cols-2 & {
    @include breakpoint($medium-down) {
      width: 50%;
      max-width: 50%;
    }
  }
  .product-grid--small-cols-3 & {
    @include breakpoint($medium-down) {
      width: 33.333%;
      max-width: 33.333%;
    }
  }
  .product-grid--small-cols-4 & {
    @include breakpoint($medium-down) {
      width: 25%;
      max-width: 25%;
    }
  }
  &.handle-image {
    @include breakpoint($large-up) {
      max-width: 50%;
    }
    @include breakpoint($medium-only) {
      max-width: 50%;
    }
  }
}

// No results message when filtering. Hidden by default.
.product-grid__no-matches {
  display: none;
  text-align: center;
  padding: 30px 20px 50px 20px;
  @include breakpoint($large-up) {
    padding: 60px 35px 80px 35px;
  }
}

.product-grid__add-all-to-bag {
  text-align: center;
  margin: 15px 0;
  padding: 0 10px;
  @include breakpoint($medium-up) {
    margin: 20px 0;
  }
}

// Plus icons are only displayed if 'add all to bag' button and carousel is enabled.
.product-grid--add-all-bag {
  &.product-grid--carousel--small-2 {
    @include icon('utility_plus-regular', before);
    &:before,
    &:after {
      position: absolute;
      top: 14%;
      #{$ldirection}: 45%;
      font-size: 30px;
      z-index: 10;
      color: $color-gray;
    }
  }
  @include breakpoint($medium-only) {
    &.product-grid--carousel--medium-2,
    &.product-grid--carousel--medium-3,
    &.product-grid--carousel--medium-4,
    &.product-grid--carousel--medium-4 .slick-list {
      @include icon('utility_plus-regular', before);
      &:before,
      &:after {
        position: absolute;
        top: 27%;
        #{$ldirection}: 49%;
        font-size: 30px;
        z-index: 10;
        color: $color-black;
      }
    }
    &.product-grid--carousel--medium-3,
    &.product-grid--carousel--medium-4 {
      @include icon('utility_plus-regular', after);
      &:before,
      &:after {
        top: 27%;
        #{$ldirection}: 32%;
      }
      &:after {
        #{$ldirection}: 64%;
      }
    }
    &.product-grid--carousel--medium-4 {
      &:before,
      &:after {
        top: 26%;
        #{$ldirection}: 23%;
      }
      &:after {
        #{$ldirection}: 48.5%;
      }
      .slick-list {
        &:before {
          top: 27%;
          #{$ldirection}: 73%;
        }
      }
    }
  }
  @include breakpoint($large-up) {
    &.product-grid--carousel--large-2,
    &.product-grid--carousel--large-3,
    &.product-grid--carousel--large-4,
    &.product-grid--carousel--large-4 .slick-list {
      @include icon('utility_plus-regular', before);
      &:before,
      &:after {
        position: absolute;
        top: 40%;
        #{$ldirection}: 49%;
        font-size: 30px;
        z-index: 10;
        color: $color-black;
      }
    }
    &.product-grid--carousel--large-3,
    &.product-grid--carousel--large-4 {
      @include icon('utility_plus-regular', after);
      &:before,
      &:after {
        top: 31%;
        #{$ldirection}: 33%;
      }
      &:after {
        #{$ldirection}: 65%;
      }
    }
    &.product-grid--carousel--large-4 {
      &:before,
      &:after {
        top: 30%;
        #{$ldirection}: 23%;
      }
      &:after {
        #{$ldirection}: 48.5%;
      }
      .slick-list {
        &:before {
          top: 32%;
          #{$ldirection}: 73%;
        }
      }
    }
  }
}

.bcc-hio {
  .mpp-container--custom {
    width: 1070px;
    margin: 0 auto;
    .product-grid__content {
      .product-grid__item.first {
        width: 60%;
      }
      .grid__item--last-col {
        width: 40%;
      }
    }
  }
}
