@import 'icons';

// Common styles used by all icons in HTML and CSS
@mixin font-icon-base() {
  font-family: 'icons';
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  font-weight: normal;
  font-style: normal;
  speak: none;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin icon($icon: $icon, $pseudo: before, $mq: false, $text-replace: false) {
  &:#{$pseudo} {
    content: map-get($icons, $icon);
    // If in a media query, include instead of extend
    @if $mq == true {
      @include font-icon-base();
    } @else {
      @include font-icon-base();
    }
    @content;
  }
  // Replace text with icon, like classic sprites
  @if $text-replace {
    @include font-icon-replace($pseudo);
  }
}

// Replace text with a font icon
@mixin font-icon-replace($pseudo: before) {
  position: relative;
  right: 9999px;
  &:#{$pseudo} {
    position: absolute;
    height: 100%;
    text-align: center;
    top: 0;
    right: -9999px;
  }
}
