// This file is generated by `gulp icons`, do not modify

$icons: (
  arrow-white--up: "\EA01",
  arrow_carousel_left: "\EA02",
  arrow_carousel_right: "\EA03",
  arrow_thin_next-forward: "\EA04",
  arrow_thin_previous-back: "\EA05",
  bag: "\EA06",
  bb-rewards_pts_icon: "\EA07",
  benefit_brushable: "\EA08",
  benefit_color-care-: "\EA09",
  benefit_color-care: "\EA0A",
  benefit_curl-care: "\EA0B",
  benefit_de-frizzes: "\EA0C",
  benefit_defines: "\EA0D",
  benefit_detangles: "\EA0E",
  benefit_dry-cleansing-1: "\EA0F",
  benefit_dry-cleansing-2: "\EA10",
  benefit_gentle: "\EA11",
  benefit_heat-protective: "\EA12",
  benefit_hold: "\EA13",
  benefit_hydrates-1: "\EA14",
  benefit_hydrates-2: "\EA15",
  benefit_lightweight: "\EA16",
  benefit_oil-infused: "\EA17",
  benefit_prevents-breakage-1: "\EA18",
  benefit_prevents-breakage-2: "\EA19",
  benefit_primes: "\EA1A",
  benefit_repairs: "\EA1B",
  benefit_reparative: "\EA1C",
  benefit_replenshing: "\EA1D",
  benefit_scalp-care: "\EA1E",
  benefit_shine: "\EA1F",
  benefit_smoothes: "\EA20",
  benefit_softens: "\EA21",
  benefit_strengthens-1: "\EA22",
  benefit_strengthens-2: "\EA23",
  benefit_strengthens-3: "\EA24",
  benefit_texture: "\EA25",
  benefit_uv-protective: "\EA26",
  benefit_volume: "\EA27",
  caret--down: "\EA28",
  caret--left: "\EA29",
  caret--right: "\EA2A",
  caret--up: "\EA2B",
  check-circled: "\EA2C",
  checkbox--checked: "\EA2D",
  checkbox-alt--checked: "\EA2E",
  checkbox-alt: "\EA2F",
  checkbox: "\EA30",
  checkout-mobile-line: "\EA31",
  circle-caret--left: "\EA32",
  circle-caret--right: "\EA33",
  close: "\EA34",
  email: "\EA35",
  facebook: "\EA36",
  form_dropdown-arrow: "\EA37",
  form_submit-arrow: "\EA38",
  hamburger: "\EA39",
  head--outline: "\EA3A",
  head--solid: "\EA3B",
  heart--filled: "\EA3C",
  heart--outline: "\EA3D",
  icon--arrow-left-lrg--black: "\EA3E",
  icon--arrow-right-lrg--black: "\EA3F",
  icon-close: "\EA40",
  instagram: "\EA41",
  livechat: "\EA42",
  location--filled: "\EA43",
  location: "\EA44",
  logo: "\EA45",
  map-marker-alt: "\EA46",
  map-marker: "\EA47",
  minus: "\EA48",
  pinterest--circle: "\EA49",
  pinterest: "\EA4A",
  plus: "\EA4B",
  product_callout_award-winner: "\EA4C",
  product_callout_limited-edition: "\EA4D",
  product_callout_new: "\EA4E",
  product_callout_online-exclusive: "\EA4F",
  product_callout_stylist-pick: "\EA50",
  product_callout_top-seller: "\EA51",
  radio--checked: "\EA52",
  radio-alt--checked: "\EA53",
  radio-alt: "\EA54",
  radio: "\EA55",
  reviews_negative: "\EA56",
  reviews_positive: "\EA57",
  search: "\EA58",
  social-fb: "\EA59",
  social-instagram: "\EA5A",
  social-pinterest: "\EA5B",
  social-twitter: "\EA5C",
  social-youtube: "\EA5D",
  social_filled_fb: "\EA5E",
  social_filled_instagram: "\EA5F",
  social_filled_pinterest: "\EA60",
  social_filled_snapchat: "\EA61",
  social_filled_tumblr: "\EA62",
  social_filled_twitter: "\EA63",
  social_filled_vimeo: "\EA64",
  social_filled_youtube: "\EA65",
  social_outline_fb: "\EA66",
  social_outline_instagram: "\EA67",
  social_outline_pinterest: "\EA68",
  social_outline_snapchat: "\EA69",
  social_outline_tumblr: "\EA6A",
  social_outline_twitter: "\EA6B",
  social_outline_vimeo: "\EA6C",
  social_outline_youtube: "\EA6D",
  stars: "\EA6E",
  utility_accordion_minus: "\EA6F",
  utility_accordion_plus: "\EA70",
  utility_bb-rewards: "\EA71",
  utility_bottles-offer: "\EA72",
  utility_chat: "\EA73",
  utility_check-circle: "\EA74",
  utility_check-regular: "\EA75",
  utility_customer-service: "\EA76",
  utility_difficulty-level: "\EA77",
  utility_difficulty-level2: "\EA78",
  utility_diy-time: "\EA79",
  utility_diy-time2: "\EA7A",
  utility_download: "\EA7B",
  utility_email-share: "\EA7C",
  utility_email-signup: "\EA7D",
  utility_filled_details: "\EA7E",
  utility_filled_heart: "\EA7F",
  utility_filled_hover-play: "\EA80",
  utility_filled_my-account: "\EA81",
  utility_filled_play-circle: "\EA82",
  utility_filled_plus-large: "\EA83",
  utility_general-pin: "\EA84",
  utility_information-general: "\EA85",
  utility_live-chat: "\EA86",
  utility_offer_bolt: "\EA87",
  utility_offers: "\EA88",
  utility_outlined_details: "\EA89",
  utility_outlined_heart: "\EA8A",
  utility_outlined_hover-play: "\EA8B",
  utility_outlined_my-account: "\EA8C",
  utility_outlined_play-circle: "\EA8D",
  utility_outlined_plus-large: "\EA8E",
  utility_pager_empty: "\EA8F",
  utility_pager_filled: "\EA90",
  utility_phone: "\EA91",
  utility_plus-regular: "\EA92",
  utility_profile_filled_man: "\EA93",
  utility_profile_filled_woman: "\EA94",
  utility_profile_outlined_man: "\EA95",
  utility_profile_outlined_woman: "\EA96",
  utility_ratings_filled_star: "\EA97",
  utility_ratings_outlined_star: "\EA98",
  utility_refer: "\EA99",
  utility_refresh: "\EA9A",
  utility_regular-minus: "\EA9B",
  utility_salon-finder: "\EA9C",
  utility_salon-pin: "\EA9D",
  utility_share: "\EA9E",
  utility_shop-all: "\EA9F",
  utility_sms: "\EAA0",
  utility_stack: "\EAA1",
  utility_use-location: "\EAA2",
  utility_view_grid_2x: "\EAA3",
  utility_view_grid_3x: "\EAA4",
  utility_view_stacked: "\EAA5",
  utillity_information: "\EAA6",
  youtube--play: "\EAA7",
  youtube: "\EAA8",
);
